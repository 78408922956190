import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { tr } from 'mmfintech-commons'

import { PaymentMethodButton } from '../PaymentMethodButton'
import { SelectMethodContainer, SelectMethodEmpty, SelectMethodTitle, SelectMethodWrapper } from './SelectMethod.styled'

interface SelectMethodProps {
  method: string
  setMethod: (method: string) => void
  onContinue?: () => void
  errorVisible: boolean
  disabled?: boolean
}

export const SelectMethod = (props: SelectMethodProps) => {
  const { method, setMethod, onContinue, errorVisible, disabled } = props

  const { checkoutOptions } = useSelector(
    ({ checkout: { checkoutOptions } }) => ({
      checkoutOptions
    }),
    shallowEqual
  )

  useEffect(() => {
    if (Array.isArray(checkoutOptions) && checkoutOptions.length === 1) {
      setMethod(checkoutOptions[0].paymentMethod)
    }
    // eslint-disable-next-line
  }, [checkoutOptions])

  if (Array.isArray(checkoutOptions) && checkoutOptions.length) {
    return (
      <SelectMethodWrapper>
        <SelectMethodTitle>{tr('CHECKOUT.PAYMENT.METHOD_LABEL', 'Payment method')}</SelectMethodTitle>
        <SelectMethodContainer>
          {checkoutOptions.map(option => (
            <PaymentMethodButton
              key={option.paymentMethod}
              name={option.paymentMethod}
              disabled={disabled}
              selected={option.paymentMethod === method}
              onClick={() => setMethod(option.paymentMethod)}
              onContinue={onContinue}
              data-test={`method-${option.paymentMethod}`}
            />
          ))}
        </SelectMethodContainer>
      </SelectMethodWrapper>
    )
  }

  if (errorVisible) {
    return (
      <SelectMethodWrapper>
        <SelectMethodEmpty data-test='no-methods-error'>
          {tr('CHECKOUT.PAYMENT.NO_METHODS', 'No payment methods available')}
        </SelectMethodEmpty>
      </SelectMethodWrapper>
    )
  }

  return null
}
