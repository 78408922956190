import { shallowEqual, useSelector } from 'react-redux'

import { Slide } from 'react-awesome-reveal'
import { BankIcon } from '../../icons'
import { CustomSelect } from '../CustomSelect'
import { ErrorText, SelectBankContainer, SelectBankWrapper } from './SelectBankPane.styled'

import { configuration, extractBankName, extractBankNameDomestic } from 'mmfintech-checkout-commons'
import { isBase64EncodedImage, tr } from 'mmfintech-commons'

import { SupportedBank } from 'mmfintech-commons-types'
import { UseFilteredBanksResult } from 'mmfintech-checkout-commons'

import BankGroupIcon from '../../assets/icons/bank-group.png'

type SelectBankPaneProps = {
  onContinue: () => void
  selectedBankId: string
  filteredBanks: UseFilteredBanksResult
  loading?: boolean
}

export const SelectBankPane = (props: SelectBankPaneProps) => {
  const { onContinue, selectedBankId, filteredBanks, loading } = props

  const { banksLoaded } = useSelector(
    ({ checkout: { banksLoaded } }) => ({
      banksLoaded
    }),
    shallowEqual
  )

  const prepareIcon = (bank: SupportedBank) => {
    if (bank.logoUrl) {
      return (
        <img
          src={
            isBase64EncodedImage(bank.logoUrl) || bank.logoUrl.substring(0, 4) === 'http'
              ? bank.logoUrl
              : configuration.readBackendConfig() + bank.logoUrl
          }
          alt=''
        />
      )
    }

    return Array.isArray(bank.options) && bank.options.length ? <img src={BankGroupIcon} alt='' /> : <BankIcon />
  }

  if (!banksLoaded) {
    return null
  }

  const handleBankClick = (bank: SupportedBank): void => {
    if (selectedBankId === bank.bankChoiceId) {
      onContinue()
    } else {
      filteredBanks.handleSelectBank(bank)
    }
  }

  if (filteredBanks.banks?.length || !filteredBanks.treeMode) {
    return (
      <SelectBankWrapper>
        <SelectBankContainer>
          {filteredBanks.hasBack ? (
            <CustomSelect
              back
              selected
              data-test='banks-back'
              onClick={filteredBanks.handleBack}
              caption={extractBankName(filteredBanks.selectedGroup)}
              description={extractBankNameDomestic(filteredBanks.selectedGroup)}
              loading={loading}
            />
          ) : null}

          {Array.isArray(filteredBanks.banks) && filteredBanks.banks.length ? (
            <Slide
              direction='right'
              cascade
              damping={0.05}
              duration={filteredBanks.selectedGroup ? 500 : 0}
              triggerOnce>
              {filteredBanks.banks.map((bank: SupportedBank) => (
                <CustomSelect
                  key={bank.bankChoiceId}
                  caption={extractBankName(bank)}
                  description={extractBankNameDomestic(bank)}
                  selected={selectedBankId === bank.bankChoiceId}
                  onClick={() => handleBankClick(bank)}
                  icon={prepareIcon(bank)}
                  data-test={`bank-${bank.bankChoiceId}`}
                  loading={loading}
                  maintenance={bank.underMaintenance}
                  className={filteredBanks.hasBack ? 'bank-inner' : ''}
                />
              ))}
            </Slide>
          ) : !filteredBanks.treeMode ? (
            <ErrorText data-test='error-text'>
              {filteredBanks.bankSearchText?.length > 2
                ? tr('CHECKOUT.PAYMENT.NO_BANKS_FOUND', 'No banks found')
                : tr('CHECKOUT.PAYMENT.SEARCH_BANK_HINT', 'Type 3 or more characters to filter banks…')}
            </ErrorText>
          ) : null}
        </SelectBankContainer>
      </SelectBankWrapper>
    )
  }

  if (filteredBanks.treeMode) {
    return (
      <ErrorText className='no-margin' data-test='error-text'>
        {tr('CHECKOUT.PAYMENT.NO_BANKS.MESSAGE', 'No banks available')}
      </ErrorText>
    )
  }

  return null
}
