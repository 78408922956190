import { Link } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import { tr } from 'mmfintech-commons'

import { SelectMethod } from '../../components'
import { ErrorDisplay, Input } from 'mmfintech-portal-commons'
import { CancelContainer, PaymentContent } from './Checkout.styled'

import { CountryChoice } from 'mmfintech-commons-types'

type SelectMethodStepProps = {
  countryCode: string
  setCountryCode: (code: string) => void
  paymentMethod: string
  setPaymentMethod: (method: string) => void
  onContinue: () => void
  showCancel: boolean
  onCancel: () => void
  disabled?: boolean
}

export const SelectMethodStep = (props: SelectMethodStepProps) => {
  const { countryCode, setCountryCode, paymentMethod, setPaymentMethod, onContinue, showCancel, onCancel, disabled } =
    props

  const { checkoutCountries, checkoutError, checkoutOptionsFetching, session } = useSelector(
    ({ checkout: { checkoutCountries, checkoutError, checkoutOptionsFetching, session } }) => ({
      checkoutCountries,
      checkoutError,
      checkoutOptionsFetching,
      session
    }),
    shallowEqual
  )

  const isCountrySelectionVisible = () =>
    Array.isArray(checkoutCountries) && checkoutCountries.length > 1 && !session?.country

  const handleCountryChanged = (_name: string, value: string) => setCountryCode(value)

  return (
    <>
      <ErrorDisplay error={checkoutError} />

      <PaymentContent>
        {isCountrySelectionVisible() ? (
          <div className='mb-4'>
            <Input
              type='select'
              name='countryCode'
              data-test='country-select'
              label={tr('CHECKOUT.PAYMENT.COUNTRY_PLACEHOLDER', 'Select country')}
              options={checkoutCountries?.map((v: CountryChoice) => ({
                value: v.countryCode,
                label: v.name
              }))}
              value={countryCode}
              onChange={handleCountryChanged}
              hideErrorLine
            />
          </div>
        ) : null}

        <SelectMethod
          method={paymentMethod}
          setMethod={setPaymentMethod}
          onContinue={onContinue}
          disabled={disabled}
          errorVisible={countryCode && !checkoutOptionsFetching}
        />

        {showCancel ? (
          <CancelContainer>
            <Link to='#' onClick={onCancel} data-test='cancel-button'>
              {tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            </Link>
          </CancelContainer>
        ) : null}
      </PaymentContent>
    </>
  )
}
