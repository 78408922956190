import styled from 'styled-components'

import { configuration } from 'mmfintech-checkout-commons'
import { getPaymentMethodImageUrl, isValidString } from 'mmfintech-commons'

import BankwireImage from '../../assets/methods/bank-transfer.png'
import CreditCardImage from '../../assets/methods/credit-card.png'
// import CryptoImage from '../../assets/methods/crypto.svg'
import InstantBankTransferImage from '../../assets/methods/instant-bank-transfer.png'

export const PaymentMethodImage = ({ method }) => {
  //const url = `${configuration.readBackendConfig()}/api${getPaymentMethodImageUrl(method)}`
  // const webResource = useWebResource(url)

  const getImage = () => {
    if (isValidString(method)) {
      switch (method) {
        case 'EMONEY':
        case 'BANKWIRE':
        case 'LOCAL_BANKWIRE':
          return <img src={BankwireImage} alt='' className='local' />

        case 'COMMUNITY_INSTANT_BANK_TRANSFER':
        case 'DIRECT_INSTANT_BANK_TRANSFER':
        case 'INSTANT_BANK_TRANSFER':
          return <img src={InstantBankTransferImage} alt='' className='local' />

        case 'CREDIT_CARD':
          return <img src={CreditCardImage} alt='' className='local' />

        // case 'CRYPTO':
        // case 'INSTANT_CRYPTO':
        //   return <img src={CryptoImage} alt='' className='method-image' />

        default:
          return <img src={`${configuration.readBackendConfig()}${getPaymentMethodImageUrl(method)}`} alt='' />
      }
    }

    return null
  }

  return <ImageWrapper>{getImage()}</ImageWrapper>
}

const ImageWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 0;

  & > img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 1rem;

    &.local {
      width: 80%;
      border-radius: 0;
    }
  }
`
