import { Link } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import { tr } from 'mmfintech-commons'

import { SelectBankPane } from '../../components'
import { ErrorDisplay, Input } from 'mmfintech-portal-commons'
import { BackIcon, SearchIcon } from '../../icons'
import { BackWrapper, CancelContainer, MethodWrapper, PaymentContent } from './Checkout.styled'

type SelectBankStepProps = {
  backCaption: string
  backVisible: boolean
  onBack: () => void
  paymentMethod: string
  formValues: any
  filteredBanks: any
  countryCode: string
  onContinue: () => void
  cancelVisible: boolean
  onCancel: () => void
  handleSearchFocus: () => void
  handleSearchType: () => void
}

export const SelectBankStep = (props: SelectBankStepProps) => {
  const {
    backCaption,
    backVisible,
    onBack,
    paymentMethod,
    formValues,
    filteredBanks,
    countryCode,
    onContinue,
    cancelVisible,
    onCancel,
    handleSearchFocus,
    handleSearchType
  } = props

  const { banksCount, checkoutError, checkoutPayFetching } = useSelector(
    ({ checkout: { banksCount, checkoutError, checkoutPayFetching } }) => ({
      banksCount,
      checkoutError,
      checkoutPayFetching
    }),
    shallowEqual
  )

  return (
    <PaymentContent>
      {backVisible ? (
        <BackWrapper>
          <span onClick={onBack} data-test='back-button'>
            <BackIcon />
            <span>{backCaption}</span>
          </span>
        </BackWrapper>
      ) : null}

      {paymentMethod ? (
        <MethodWrapper data-test={`pay-with-${paymentMethod}-banks`}>
          {tr('CHECKOUT.PAYMENT.PAY_BY', 'Pay with {{METHOD}}', {
            METHOD: tr('METADATA.PAYMENT_OPTIONS.' + paymentMethod, paymentMethod)
          })}
        </MethodWrapper>
      ) : null}

      <ErrorDisplay error={checkoutError} />

      {banksCount > 10 ? (
        <div className='bank-search'>
          <SearchIcon />
          <Input
            key='bank-search'
            type='text'
            name='bank-search'
            data-test='bank-search-input'
            maxLength={50}
            value={filteredBanks.bankSearchText}
            onChange={(_name: string, value: string) => {
              filteredBanks.setBankSearchText(value)
              if (value?.length) {
                handleSearchType()
              }
            }}
            onFocus={handleSearchFocus}
            placeholder={
              countryCode === 'DEU'
                ? tr('CHECKOUT.PAYMENT.BANK_SEARCH_DEU', 'Search by bank name or BLZ')
                : tr('CHECKOUT.PAYMENT.BANK_SEARCH', 'Search by bank name')
            }
            hideErrorLine
            autoComplete='off'
          />
        </div>
      ) : null}

      <SelectBankPane
        filteredBanks={filteredBanks}
        selectedBankId={formValues.getValue('bankChoiceId')}
        onContinue={onContinue}
        loading={checkoutPayFetching}
      />

      <div className='buttons'>
        {cancelVisible ? (
          <CancelContainer>
            <Link to='#' onClick={onCancel} data-test='cancel-button'>
              {tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            </Link>
          </CancelContainer>
        ) : null}
      </div>
    </PaymentContent>
  )
}
