import brandLogo from './assets/images/banky-logo-black.png'

const theme = {
  brandLogo,

  fontName: 'Rubik',

  toast: {
    error: {
      iconTheme: { primary: 'red', secondary: 'white' },
      style: {
        color: '#ffffff',
        fontSize: '1.6rem',
        lineHeight: '1.9rem',
        background: '#ff4c4d',
        borderRadius: '1rem'
      }
    },
    success: {
      iconTheme: { primary: 'green', secondary: 'white' },
      style: {
        color: '#ffffff',
        fontSize: '1.6rem',
        lineHeight: '1.9rem',
        background: '#7cc670',
        borderRadius: '1rem'
      }
    }
  },

  arrowButton: {
    width: '16px',
    height: '12px',
    textColor: '#939ea7',
    hoverColor: '#828282',
    secondaryHoverColor: '#828282'
  },

  button: {
    loadingType: 'ThreeDots',
    primary: {
      height: '5rem',
      background: '#1e63ff',
      border: 'none',
      borderRadius: '1rem',
      boxShadow: '0 5px 17px #9fc6ff',
      color: '#ffffff',
      fontSize: '1.6rem',
      fontWeight: '500',
      iconColor: '#ffffff',
      iconSpacing: '1.4rem',
      padding: '0 1rem',

      hoverBackground: 'rgba(30, 99, 255, 0.75)',
      hoverBorder: 'none',
      hoverBoxShadow: '0 5px 17px #9fc6ff',
      hoverColor: '#ffffff',

      disabledColor: '#ffffff',
      disabledBackground: 'rgba(30, 99, 255, 0.3)',
      disabledBorder: 'none',
      disabledBoxShadow: '0 5px 30px rgba(172, 182, 191, 0.25)'
    },
    secondary: {
      height: '5rem',
      background: '#ffffff',
      border: '1px solid #ffffff',
      borderRadius: '1rem',
      boxShadow: 'none',
      color: 'rgba(30, 99, 255, 0.75)',
      fontSize: '1.6rem',
      fontWeight: '500',
      iconColor: 'rgba(30, 99, 255, 0.75)',
      iconSpacing: '1.4rem',
      padding: '0 1rem',

      hoverBackground: '#ffffff',
      hoverBorder: '1px solid #dfe8ec',
      hoverBoxShadow: 'none',
      hoverColor: '#1e63ff',

      disabledColor: '#ffffff',
      disabledBackground: '#d5dbe2',
      disabledBorder: '1px solid #d5dbe2',
      disabledBoxShadow: '0 5px 30px rgba(172, 182, 191, 0.25)'
    },
    alternative: {
      height: '5rem',
      background: '#ffce32',
      border: 'none',
      borderRadius: '1rem',
      boxShadow: 'none',
      color: '#ffffff',
      fontSize: '1.6rem',
      fontWeight: '500',
      iconColor: '#ffffff',
      iconSpacing: '1.4rem',
      padding: '0 1rem',

      hoverBackground: '#ffd44c',
      hoverBorder: 'none',
      hoverBoxShadow: 'none',
      hoverColor: '#ffffff',

      disabledColor: '#ffffff',
      disabledBackground: '#d5dbe2',
      disabledBorder: 'none',
      disabledBoxShadow: '0 5px 30px rgba(172, 182, 191, 0.25)'
    },
    'round-primary': {
      height: '4rem',
      background: '#ffffff',
      border: 'none',
      borderRadius: '2rem',
      boxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
      color: '#000000',
      fontSize: '1.4rem',
      fontWeight: '600',
      iconColor: '#fec72b',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2.5rem',

      hoverBackground: '#f9f9fb',
      hoverBorder: 'none',
      hoverBoxShadow: '0 2px 15px rgba(0, 0, 0, 0.15)',
      hoverColor: '#828282',

      disabledColor: '#828282',
      disabledBackground: '#e5e5e5',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    },
    'round-secondary': {
      height: '4rem',
      background: '#ffffff',
      border: 'none',
      borderRadius: '2rem',
      boxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
      color: '#000000',
      fontSize: '1.4rem',
      fontWeight: '600',
      iconColor: '#fec72b',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2.5rem',

      hoverBackground: '#338aff',
      hoverBorder: 'none',
      hoverBoxShadow: '0 2px 15px rgba(0, 0, 0, 0.15)',
      hoverColor: '#ffffff',

      disabledColor: '#828282',
      disabledBackground: '#e5e5e5',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    },
    delete: {
      height: '4rem',
      background: '#ffffff',
      border: 'none',
      borderRadius: '2rem',
      boxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
      color: '#2f80ed',
      fontSize: '1.4rem',
      fontWeight: '600',
      iconColor: '#fec72b',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2.5rem',

      hoverBackground: '#ffffff',
      hoverBorder: 'none',
      hoverBoxShadow: '0 2px 15px rgba(0, 0, 0, 0.15)',
      hoverColor: '#2f80ed',

      disabledColor: '#828282',
      disabledBackground: '#e5e5e5',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    }
  },

  checkbox: {
    checkedIconBackgroundColor: '#1e63ff',
    checkedIconBorderColor: '#ffffff',
    iconBackground: '#ffffff',
    iconBorder: '1px solid #dadada',
    iconBorderRadius: '5px',
    iconSize: '22px',
    fontSize: '1.4rem',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textColor: '#454a54'
  },

  datepicker: {
    container: {
      backgroundColor: '#ffffff',
      borderColor: '#e1e5ea',
      borderRadius: '1rem',
      boxShadow: 'none', //0 5px 30px rgba(172, 182, 191, 0.25)
      fontSize: '1.2rem',
      padding: '0 0 1rem'
    },
    dayName: {
      fontSize: '1.2rem',
      fontWeight: '500',
      lineHeight: '2.6rem',
      textColor: '#000000',
      marginTop: '2rem',
      marginBottom: '-8px'
    },
    header: {
      arrowSize: '2px',
      arrowTop: '1.5rem',
      arrowLeftRight: '1rem',
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      fontSize: '1.4rem',
      fontWeight: '500',
      lineHeight: '2.6rem',
      textColor: '#000000',
      padding: '2rem 2rem 0'
    },
    cells: {
      border: '1px solid transparent',
      borderRadius: '50%',
      height: '3rem',
      width: '3rem',
      fontSize: '1.2rem',
      fontWeight: '400',
      textColor: '#828282',
      outsideColor: '#a3b1bf',
      margin: '2px 4px',
      hoverBackground: 'transparent',
      hoverBorder: '1px solid #b4bfcb',
      hoverTextColor: '#000000',
      selectedBackground: '#1e63ff',
      selectedBorder: '1px solid #1e63ff',
      selectedColor: '#ffffff'
    }
  },

  dataTable: {
    body: {
      backgroundColor: '#ffffff',
      border: '1px solid #e5e5e5',
      borderColor: '#e5e5e5',
      textColor: '#000000',
      fontSize: '1.6rem',
      fontWeight: '500',
      dropShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
      infoLineColor: '#939ea7'
    },
    head: {
      background: '#f2f2f3',
      border: '2px solid #e5e5e5',
      textColor: '#939ea7',
      fontSize: '1.6rem',
      fontWeight: '500'
    },
    hover: {
      backgroundColor: '#f0f7ff',
      border: '1px solid #e5e5e5',
      textColor: '#000000'
    }
  },

  dropTarget: {
    background: 'rgba(245, 181, 0, 0.8)',
    border: 'none',
    borderRadius: '2rem',
    textColor: '#000000',
    fontSize: '1.6rem',
    fontWeight: 'normal'
  },

  finalMessage: {
    fontSize: '1.6rem',
    fontWeight: '500',
    textColor: '#000000',
    infoTextColor: '#2f80ed',
    dangerTextColor: '#ee2b2b',
    successTextColor: '#59b300',
    padding: '0 2.5rem 4.5rem',
    contentPadding: '2.5rem 0 0'
  },

  gridTable: {
    border: '1px solid #e5e5e5',
    fontSize: '1.4rem',
    fontSize2: '1.6rem',
    fontWeight: '500',
    lineHeight: '1.7rem',
    lineHeight2: '2rem',
    labelColor: '#85a0ad',
    valueColor: '#000000',
    negativeColor: '#ee2b2b',
    positiveColor: '#59b300',
    rowPadding: '1rem 0',
    totalFontSize: '2rem',
    totalFontSize2: '2.4rem',
    totalFontWeight: 'bold',
    totalLineHeight: '2.4rem',
    totalLineHeight2: '3rem'
  },

  input: {
    label: {
      textColor: '#454a54',
      fontSize: '1.2rem',
      fontWeight: '500',
      lineHeight: '1.4rem',
      textTransform: 'none',
      marginBottom: '1rem',
      marginLeft: '0',
      asteriskColor: '#ff4c4d',
      asteriskMargin: '0',
      asteriskPosition: 'right',
      paddingWithIcon: '2.4rem',
      paddingRequired: '1.7rem'
    },
    error: {
      textColor: '#ff4c4d',
      padding: '0',
      marginTop: '0.8rem'
    },
    wrapper: {
      marginBottom: '2rem'
    },
    container: {
      textColor: '#454a54',
      fontSize: '1.4rem',
      fontWeight: 'normal',
      lineHeight: '1.7rem',
      background: '#ffffff',
      border: '1px solid #e1e6ea',
      borderWidth: '1px',
      borderRadius: '1rem',
      padding: '0 1.6rem',
      iconColor: '#a3b1bf',
      errorBorderColor: '#ff4c4d',
      errorBorderWidth: '1px',
      focusedBorderColor: 'rgba(30, 99, 255, 0.3)',
      focusedBorderWidth: '2px'
    },
    placeholder: {
      textColor: '#a3b1bf',
      fontSize: '1.4rem',
      fontWeight: 'normal',
      lineHeight: '1.7rem'
    },
    dropDown: {
      controlBorder: 'none',
      controlBorderRadius: '0',
      controlPadding: '0',
      indicatorColor: '#a3b1bf',
      menuBackground: '#ffffff',
      menuBorder: 'none',
      menuBorderRadius: '1rem',
      menuMargin: '1rem -1.5rem 0 -1.5rem',
      menuPadding: '0.5rem 0',
      menuWidth: 'calc(100% + 3rem)',
      openedBackground: 'transparent',
      openedBorder: 'none',
      openedBorderRadius: '0',
      openedColor: '#454a54',
      optionBorderRadius: '0',
      optionColor: '#454a54',
      optionFontSize: '1.4rem',
      optionFontWeight: '400',
      optionLineHeight: 'normal',
      optionMargin: '0',
      optionPadding: '0.8rem 2rem',
      optionBackground: '#ffffff',
      focusedOptionColor: '#000000',
      focusedOptionBackground: '#e7ebee',
      buttonColor: '#eff8e6',
      buttonTextAlign: 'center',
      buttonBorderRadius: '3px',
      buttonBackground: '#1a1a1a'
    },
    settings: {
      height: '4.4rem',
      singleSelect: false,
      autofillBackground: '#ffffff',
      dropDownArrowColor: '#000000',
      textareaMargin: '0',
      textareaPadding: '1.6rem 0',
      hideLabelWhenEmpty: false,
      showPasswordWithText: false
    }
  },

  loader: {
    spinnerColor: '#828282',
    spinnerSize: 55,
    spinnerType: 'TailSpin',
    loaderColor: '#828282',
    loaderSize: 75
  },

  noElementsMessage: {
    background: '#ffffff',
    border: 'none',
    borderRadius: '1rem',
    boxShadow: '0 5px 30px rgba(172, 182, 191, 0.25)',
    minHeight: '15rem',
    padding: '2rem',
    textColor: '#b4bfcb',
    fontSize: '1.8rem',
    fontWeight: '400',
    lineHeight: '2.1rem'
  },

  pagination: {
    marginPages: 2,
    minPagesVisible: 0,
    noArrowButtons: true,
    showPageLabel: true,
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '1.7rem',
    textColor: '#454a54',
    backgroundColor: 'transparent',
    buttonBorderColor: 'transparent',
    buttonRadius: '1rem',
    buttonMargin: '1rem',
    buttonSize: '3rem',
    buttonTextColor: '#454a54',
    buttonHoverBorderColor: 'transparent',
    buttonHoverTextColor: '#1e63ff',
    buttonActiveBackground: '#ffffff',
    buttonActiveBorderRadius: '1rem',
    buttonActiveBoxShadow: '0px 5px 30px rgba(172, 182, 191, 0.25)',
    buttonActiveTextColor: '#1e63ff'
  },

  radioButton: {
    iconSize: '2.4rem',
    iconSpacing: '1.6rem',
    fontSize: '1.6rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '2rem',
    textColor: '#828282',
    loadingColor: '#828282',
    loadingSize: '30'
  },

  switchInput: {
    width: '4.6rem',
    height: '2.6rem',
    border: 'none',
    borderRadius: '2.3rem',

    color: '#ffffff',
    fontSize: '1.6rem',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '120%',

    ballSize: '2.2rem',
    ballBackground: '#ffffff',
    ballBoxShadow: '0 2px 2px rgba(0, 0, 0, 0.24)',

    checkedBackground: 'rgba(74, 209, 27, 0.7)',
    uncheckedBackground: '#e5e5e5',

    uncheckedBallColor: '#ffffff',
    uncheckedBorder: 'none',

    disabledColor: '#4f4f4f',
    disabledBallColor: '#ffffff',
    disabledBorder: 'transparent',
    disabledBackground: 'rgba(74, 209, 27, 0.3)',
    disabledOpacity: '1'
  },

  errorDisplay: {
    borderColor: 'transparent',
    borderBottomColor: 'transparent',
    textColor: '#ee2b2b',
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '1.7rem',
    margin: '1rem 0'
  }
}

export default theme
