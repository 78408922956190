import { isLocalhost } from 'mmfintech-commons'

const host: string = typeof window !== 'undefined' ? window.location.host : ''

const isDevelopment: boolean = host.indexOf('-dev.') > -1
const isSandbox: boolean = host.indexOf('-sandbox.') > -1
export const isProduction: boolean = typeof window !== 'undefined' && !isLocalhost && !isSandbox && !isDevelopment

const settings = {
  backendForLocalhost: 'https://pay-dev.banky.io',
  // backendForLocalhost: 'https://pay-sandbox.banky.io',
  cardGatewayForLocalhost: 'https://card-dev.banky.io',
  // cardGatewayForLocalhost: 'https://card-sandbox.banky.io',
  languages: ['en', 'de', 'es', 'fr', 'hr', 'it', 'pl', 'pt', 'fi', 'sv', 'ja']
}

export default settings
