import styled from 'styled-components'

export const InstructionsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;

  color: #454a54;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: normal;

  .caption {
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 2rem;
  }

  .buttons {
    width: 100%;
    text-align: center;

    .button {
      height: 5rem;
      width: 100%;
      max-width: 40rem;

      font-size: 1.6rem;
      font-weight: 500;
    }
  }
`

export const PayIdPaneStyled = styled(InstructionsContainer)`
  align-items: center;

  .static-label {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
    width: 100%;
    text-align: left;
  }

  .static {
    border: 1px solid #e1e5ea;
    border-radius: 1rem;
    margin-bottom: 4rem;

    &.mb-1 {
      margin-bottom: 2rem;
    }

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    padding: 1.3rem 1.4rem 1.4rem 1.6rem;
    width: 100%;

    &.copied {
      background-color: rgba(30, 99, 255, 0.05);
      border-color: transparent;
    }

    .copy-button {
      cursor: pointer;
    }
  }

  .bullets {
    display: grid;
    grid-gap: 1.6rem;
    grid-template-columns: 1fr;

    .bullet {
      display: grid;
      grid-gap: 1.6rem;
      grid-template-columns: auto 1fr;

      .number {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #a3b1bf;
        border-radius: 50%;

        color: #a3b1bf;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: normal;

        height: 2rem;
        width: 2rem;
      }
    }
  }
`
