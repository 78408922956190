import React from 'react'

import CheckoutPayment from './views/CheckoutPayment'

const LazyCheckoutFailure = React.lazy(() => import('./views/CheckoutFailure'))
const LazyCheckoutPolling = React.lazy(() => import('./views/CheckoutPolling'))
const LazyCheckoutSuccess = React.lazy(() => import('./views/CheckoutSuccess'))
const LazyQRPayment = React.lazy(() => import('./views/QRPayment'))

const routes = [
  { path: '/fail/sessionId/:sessionId/lang/:languageCode', component: LazyCheckoutFailure },
  { path: '/fail/sessionId/:sessionId', component: LazyCheckoutFailure },
  { path: '/fail', component: LazyCheckoutFailure },
  { path: '/error', component: React.lazy(() => import('./views/CheckoutError')) },
  { path: '/success/sessionId/:sessionId/lang/:languageCode', component: LazyCheckoutSuccess },
  { path: '/success/sessionId/:sessionId', component: LazyCheckoutSuccess },
  { path: '/success', component: LazyCheckoutSuccess },
  { path: '/processing/sessionId/:sessionId/lang/:languageCode', component: LazyCheckoutPolling },
  { path: '/processing/sessionId/:sessionId', component: LazyCheckoutPolling },
  { path: '/processing', component: LazyCheckoutPolling },
  { path: '/qr-payment/:token/lang/:languageCode', component: LazyQRPayment },
  { path: '/qr-payment/:token', component: LazyQRPayment },
  { path: '/qr-payment', component: LazyQRPayment },
  { path: '/sessionId/:sessionId/lang/:languageCode', exact: true, component: CheckoutPayment },
  { path: '/sessionId/:sessionId', exact: true, component: CheckoutPayment },
  { path: '/', exact: true, component: CheckoutPayment },
  { path: '*', redirect: '/' }
]

export default routes
